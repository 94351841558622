import React from "react";
import { Container } from "react-bootstrap";
import EtlapblokkTartalom from "../components/EtlapblokkTartalom";

const EtlapTartalom = () => {
  return (
    <Container>
      <h1>Étlap és heti menü</h1>
      <EtlapblokkTartalom />
      <div></div>
    </Container>
  );
};

export default EtlapTartalom;
