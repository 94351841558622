import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import Fejlec from "../components/Fejlec";
import Lablec from "../components/Lablec";
import KezdolapTartalom from "../components/KezdolapTartalom";
import JobbOldalsav from "../components/JobbOldalsav";

const Kezdolap = () => {
  return (
    <>
      <Fejlec />
      <Container fluid>
        <Row>
          <Col lg={8}>
            <KezdolapTartalom />
          </Col>
          <Col lg={4}>
            <JobbOldalsav />
          </Col>
        </Row>
      </Container>
      <Lablec />
    </>
  );
};

export default Kezdolap;
