import React from "react";
import { Container } from "react-bootstrap";
import Figure from "react-bootstrap/Figure";
import hetimenuKep from "../kepek/hetimenu-0102-0108.jpg";

const EtlapblokkTartalom = () => {
  return (
    <Container>
      <div>
        <Figure>
          <Figure.Caption>Heti menü</Figure.Caption>
          <Figure.Image src={hetimenuKep} />
        </Figure>
      </div>
    </Container>
  );
};

export default EtlapblokkTartalom;
