import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Lablec = () => {
  return (
    <Container fluid className="lablec">
      <Row>
        <Col>
          <h3>Fontos információ</h3>
          <hr />
          <p>
            OTP SZÉP kártyával, MKB SZÉP kártyával és Bankkártyával is lehet
            fizetni!!!
          </p>
          <p>
            AZ ÁRAK FORINTBAN ÉRTENDŐEK ÉS TARTALMAZZÁK AZ ÁFÁT. CSOMAGOLÁS ÁRA
            +150 FT. AZ ÉTELEK ALLERGÉN ANYAGTARTALMÁRÓL AZ ÉTTEREM
            TELEFONSZÁMAN LEHET ÉRDEKLŐDNI.
          </p>
        </Col>
        <Col>
          <h3>Kapcsolat</h3>
          <hr />
          <p>
            Cím: 9700 Szombathely, Szarka Zoltán utca 3.
            <br />
            Telefon: (70) 708 45 59
            <br />
            E-mail: etterem.toscana@gmail.com
          </p>
        </Col>
        <Col>
          <h3>Üzemeltető cég</h3>
          <hr />
          <p>
            ARRABONA IMMOBILIEN Kft.
            <br />
            9025 Győr, Bécsi út 14.
            <br />
            Adószám: 24279093-2-08
            <br />
            Ügyvezető igazgató: Karácsony Tamás
            <br />
            Elérhetőség: 70/629-9135 70/708-4559
          </p>
        </Col>
        <Col>
          <h3>Nyitvatartás</h3>
          <hr />
          <p>
            Hétfő-Csütörtök 11:00 - 20:45
            <br />
            Péntek-Szombat 10:00 - 20:45
            <br />
            Vasárnap ZÁRVA
          </p>
          <h3>Adatvédelem és Impresszum</h3>
          <hr />
          <a href="adatkezeles">Adatkezelési tájékoztató</a>
          <br />
          <a href="cookie">Cookie nyilatkozat</a>
          <br />
          <a href="impresszum">Impresszum</a>
        </Col>
      </Row>
      <hr />
      <Row>
        <p>© Toscana Szombathely 2022</p>
      </Row>
    </Container>
  );
};

export default Lablec;
