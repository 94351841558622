import React from "react";
import { Link } from "react-router-dom";
import { Container, Col, Row, Alert } from "react-bootstrap";

const KezdolapTartalom = () => {
  return (
    <Container fluid>
      <Row>
        <Col className="p-4 mt-3 mb-3 bg-info rounded-3 fs-3 text-center">
          <p>
            <strong>Kedves Vásárlóink!</strong>
            <br />
            Tájékoztatjuk Önöket, hogy webshopos rendelést megszüntettük,
            ezentúl kérjük keressenek minket{" "}
            <Link to="/kapcsolat" className="text-success">
              telefonon, emailben,
            </Link>{" "}
            <a
              href="https://www.foodpanda.hu/restaurant/r1hd/toscana"
              target="_blank"
              rel="noreferrer"
              className="text-danger"
            >
              Foodpandán
            </a>{" "}
            vagy{" "}
            <a
              href="https://wolt.com/hu/hun/szombathely/restaurant/toscana"
              target="_blank"
              rel="noreferrer"
            >
              Wolton
            </a>
            !
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="text-center p-4 mb-5 bg-light rounded-3">
          <Alert variant="danger">
            Áldott, békés karácsonyi ünnepeket és boldog új évet kívánunk!
            December 24 és január 1 között zárva tartunk, ekkor megrendeléseket
            nem tudunk teljesíteni! December 24-én csak az előre megrendelt
            tálakat, ételeket adjuk ki.
          </Alert>
          <Alert variant="success">
            OTP SZÉP kártyával, MKB SZÉP kártyával és Bankkártyával is lehet
            fizetni, melyet kérünk előre jelezni, ennek hiányában csak
            készpénzzel tudnak fizetni!!!
          </Alert>
          <Alert variant="dark">
            AZ ÁRAK FORINTBAN ÉRTENDŐEK ÉS TARTALMAZZÁK AZ ÁFÁT. CSOMAGOLÁS ÁRA
            +150 FT. AZ ÉTELEK ALLERGÉN ANYAGTARTALMÁRÓL AZ ÉTTEREM
            TELEFONSZÁMÁN LEHET ÉRDEKLŐDNI. KISZÁLLÍTÁS MINIMUM 2000 FT -TÓL.
            (AZOKRA, AKIK EGÉSZ HÉTRE LEADJÁK A MENÜ RENDELÉST, NEM VONATKOZIK).
            EGYNAPI MENÜRENDELÉS LEADÁS FELTÉTEL MINIMUM 2 MENÜ MEGRENDELÉSE!
            AKI EGÉSZ HETES MENÜT RENDELÉS, ANNAK EGY MENÜT IS KISZÁLLÍTUNK.
            KÖRNYEZŐ TELEPÜLÉSEKRE A KISZÁLLÍTÁS DÍJAIRÓL AZ ÉTTEREM
            TELEFONSZÁMÁN LEHET ÉRDEKLŐDNI. TELEFON: (70) 708 45 59 TOSCANA
            SZOMBATHELY
          </Alert>
        </Col>
      </Row>
    </Container>
  );
};

export default KezdolapTartalom;
