import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import Fejlec from "../components/Fejlec";
import Lablec from "../components/Lablec";
import CookieTartalom from "../components/CookieTartalom";
import JobbOldalsav from "../components/JobbOldalsav";

const Cookie = () => {
  return (
    <>
      <Fejlec />
      <Container fluid>
        <Row>
          <Col lg={8}>
            <CookieTartalom />
          </Col>
          <Col lg={4}>
            <JobbOldalsav />
          </Col>
        </Row>
      </Container>
      <Lablec />
    </>
  );
};

export default Cookie;
